import MessageMap from '../i18n.d';

const frFR: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  searchComponent: {
    searchTitle: 'Générer des journaux d’activité',
    description:
      'Sélectionnez une application et une période allant jusqu’à 31 jours.',
    selectApplication: 'Sélectionner une application',
    startDateAndTime: 'Date et heure de début',
    endDateAndTime: 'Date et heure de fin',
    filters: 'Filtres',
    selectOperation: 'Sélectionner une opération',
    optional: '(facultatif)',
    selectEntity: 'Sélectionner une entité',
    selectCountry: 'Sélectionner un pays',
    entityIdPlaceholder: 'Saisir un ID d’entité',
    userIdPlaceholder: 'Saisir une adresse e-mail ou un ID utilisateur',
    clearFilters: 'Effacer les champs',
    showLogs: 'Afficher les journaux d’activité',
    requiredField: 'Champ obligatoire.',
    selectPeriod: 'Sélectionnez une période allant jusqu’à 31 jours.',
    invalidStartDate: 'La date de début doit être antérieure à la date de fin.',
  },
  activityComponent: {
    activityTitle: 'Journaux d’activité',
    emptyMessage: 'Les journaux d’activité apparaîtront ici.',
    filteredBy: 'Filtré par',
    clearFilters: 'Effacer les filtres',
    showResults: 'Affichage de {length} résultats',
    exportCsv: 'Exporter CSV',
    errorCsvDownload:
      "Impossible d'exporter le fichier CSV. Veuillez réessayer.",
    successCsvDownload: 'Fichier CSV exporté avec succès.',
  },
  logDetailsComponent: {
    details: 'Détails du journal',
    dateAndTime: 'Date et heure',
    copyJson: 'Copier JSON',
    metadata: 'Métadonnées',
  },
  common: {
    application: 'Application',
    operation: 'Opération',
    entity: 'Entité',
    country: 'Pays',
    entityId: 'ID d’entité',
    userId: 'ID utilisateur',
    errorToast:
      "Impossible de générer les journaux d'activité. Veuillez réessayer.",
    successToast: "Journaux d'activité générés avec succès.",
    startDate: 'Date de début',
    startTime: 'Heure de début',
    endDate: 'Date de fin',
    endTime: 'Heure de fin',
    table: {
      itemsPerPage: 'Lignes par page: {option}',
      quantityIndicator: 'sur',
      dateAndTime: 'Date et heure (UTC)',
      details: 'Détails',
      show: 'Afficher',
      emptyMessage:
        "Il n'y a aucune information à afficher. Veuillez affiner votre recherche.",
    },
  },
};

export default frFR;
